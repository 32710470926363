<template>
  <div>
    <el-container>
<!--      <CommonTree-->
<!--        ref="CommonTree"-->
<!--        style="border-right: none"-->
<!--        treeTitle="组织架构"-->
<!--        :popoverShow="false"-->
<!--        :treeData.sync="treeData"-->
<!--        :defaultProps="defaultProps"-->
<!--        :searchTitle="searchTitle"-->
<!--        :currentNodeKey="deptId"-->
<!--        :defaultCheckedKeys="defaultCheckedKeys"-->
<!--        :isShowdig="false"-->
<!--        :showCheckbox="false"-->
<!--        :treeExpand="false"-->
<!--        @getNodeClick="treeNodeClick"-->
<!--        @include-down="includeDown"-->
<!--      />-->
      <el-main>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowDetail"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #customBtn="{row}">
            <el-button
              type="text"
              v-if="row.isComplete == 2 && isTrainingEnded(row.trainingTime)"
              @click="rowContinuing(row)"
            > 继续培训
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {deptChildIds, deptChildTree, treeReport} from "@/api/reportTemplate";
import {getDetailBySiteTrainingId} from "@/api/training/recording";
import {getStatisticsPage as getPage} from "@/api/training/trainingEducation";

import {mapGetters} from "vuex";

export default {
  props:{
    type:{
      type: Object
    },
    rowData:{
      type: Object
    }
  },
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      dicData: [],
      node: {},
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      showDialog: false,
      formType: '',
      formList: {},
      treeData: [],
      selectionList: [],
      defaultProps: {
        label: "fullName",
        children: 'children'
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchTitle: 'fullName',
      deptId: '',
      defaultCheckedKeys: [],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "userName",
          span: 4,
          type: "input",
          placeholder: "请输入姓名",
        },
        {
          label: "",
          prop: "courseName",
          span: 4,
          type: "input",
          placeholder: "请输入课程名称",
        },
      ];
    },
    gridHeadBtn() {
      return [];
    },
    tableOptions() {
      return {
        menuWidth: 100,
        selection: this.type != "trainingEducationStatistics",
        linklabel: "courseName",
        menu:this.type != "trainingEducationStatistics",
        column: [
          {
            label: "课程名称",
            prop: "courseName",
            align: "center",
            overHidden: true,
          },
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            overHidden: true,
          },
          {
            label: "课程时长",
            prop: "courseDuration",
            align: "center",
            overHidden: true,
            width: 110,
          },
          {
            label: "达标课时",
            prop: "qualifyPeriod",
            align: "center",
            width: 90,
            overHidden: true,
          },
          {
            label: "完成必修课时",
            prop: "completeCompulsoryPeriod",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "是否完成",
            prop: "isComplete",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=exam_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            width: 90,
            overHidden: true,
          },
          {
            label: "观看时长",
            prop: "watchDuration",
            align: "center",
            width: 110,
            overHidden: true,
          },
          {
            label: "获得课时",
            prop: "obtainPeriod",
            align: "center",
            width: 90,
            overHidden: true,
          },
          {
            label: "成绩",
            prop: "achievement",
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "trainingTime",
            align: "center",
            width: 220,
            overHidden: true,
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree
  },
  mounted() {
    // this.getDeptChildTreeData();
    this.onLoad(this.page);
  },
  methods: {
    isTrainingEnded(trainingTime) {
      trainingTime = trainingTime.substr(-10);

      console.log(trainingTime,"trainingEndTime")
      if (!trainingTime) return false;
      const currentTime = new Date();
      const endTime = new Date(trainingTime);
      return currentTime <= endTime;
    },
    rowContinuing(row) {
      if (row.educationId) {
        this.$router.push({
          path: "/business/safetyenment/train/trainingEducation/form",
          query: {
            id: row.educationId
          },
        });
      }
    },
    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      this.onLoad(this.page);
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      if (this.$refs.gridHeadLayout.searchForm.time) {
        let selectedDate = new Date(this.$refs.gridHeadLayout.searchForm.time);
        // 将日期转换为 "yyyy-MM-dd" 格式的字符串
        this.$refs.gridHeadLayout.searchForm.time = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`
      }
      this.page = page;
      this.crudLoading = true;
      this.query.taskId = this.rowData && this.rowData.id ? this.rowData.id : ''
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    // 获取组织架构和顶部导航
    getDeptChildTreeData() {
      deptChildTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData.push(res.data.data);
          this.deptId = this.treeData[0].id;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.deptId)
          })
          this.getTopMenu()
        }
      })
    },
    getTopMenu() {
      treeReport(
        this.deptId
        // includeStatus:this.includeStatus
      ).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.replaceData(data);
          this.topSidderData = data;
        }
      })
    },
    replaceData(data) {
      data.forEach((item) => {
        if (item.reportVOList && item.reportVOList.length != 0 && item.children) {
          item.children = item.children.concat(item.reportVOList);
        } else if (item.reportVOList && item.reportVOList.length != 0 && item.children == undefined) {
          item.children = new Array();
          item.children = item.children.concat(item.reportVOList);
        } else {
          item.children = new Array();
        }
        if (item.reportName) {
          item.reportCategoryName = item.reportName;
        }
        if (item.reportReviewPath == undefined) {
          item.reportReviewPath = '';
        }
        if (item.children && item.children.length != 0) {
          this.replaceData(item.children);
        }
      })
    },
    treeNodeClick(item) {
      let params = {
        firm: item.id
      }
      this.onLoad(this.page, params)
      this.deptId = item.id;
      if (this.includeStatus) {
        this.getDeptIds(item.id);
      }
      this.getDeptUrl(this.deptId);
    },
    getDeptUrl(deptId) {
      if (this.urlPath != '') {
        if (this.includeStatus) {
          deptChildIds(deptId).then((res) => {
            if (res.data.code == 200) {
              this.deptIds = res.data.data;
              this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            }
          })
        } else {
          this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + deptId;
        }
      }
    },
    rowDetail(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/view",
        query: {
          id: row.educationId
        },
      });
    },
    includeDown(e) {
      this.includeStatus = e;
      if (this.includeStatus) {
        this.$loading()
        deptChildIds(this.deptId).then((res) => {
          if (res.data.code == 200) {
            this.deptIds = res.data.data;
            this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            this.$loading().close()
          }
        })
      } else {
        this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptId;
      }
    },
  }
}

</script>
<style scoped lang="scss">
</style>
